import React from 'react';

import Loader from 'components/Loader';
import ItemPost from 'templates/pages/CustomerStories';

import withPreview from '@/hoc/withPreview';
import { post } from 'constants/graphql/post';

// import 'sections/pages/Post/styles.scss';

const Post = ({ pageContext, loaded }) => {
  if (!loaded) return <Loader />;

  return <ItemPost {...pageContext} />;
};

const queryPreview = `
query BlogTemplatePreviewQuery($id: ID = "") {
  post(id: $id, idType: DATABASE_ID, asPreview: true) {
    ${post()}
    revisions(first:1) {
      nodes {
        ${post()}
      }
    }
  }
}
`;

export default withPreview(Post, {
  queryPreview,
});
