const post = (WpTag = '') => `
  id
  seo {
    opengraphTitle
    canonical
    metaDesc
    metaKeywords
    opengraphDescription
    opengraphImage {
      srcSet
      sourceUrl
    }
    opengraphSiteName
    opengraphUrl
    schema {
      raw
      pageType
      articleType
    }
    title
    twitterDescription
    twitterImage {
      srcSet
      sourceUrl
      uri
      title
    }
    twitterTitle
    readingTime
    opengraphType
    opengraphPublishedTime
    opengraphModifiedTime
    opengraphPublisher
  }
  title
  content
  excerpt
  featuredImage {
    node {
      altText
      sourceUrl
      srcSet
    }
  }
  categories {
    nodes {
      name
      slug
    }
  }
  tags {
    nodes {
      name
      slug
    }
  }
  dateGmt
  date
  link
  seo {
    metaDesc
    fullHead
  }
  slug
  title
  uri
  template {
    ... on ${WpTag}Template_CasePage {
      templateName
      casePage {
        storiesArticleAside {
          storiesArticleAsideImage {
            altText
            sourceUrl
            srcSet
          }
          storiesArticleAsideTitle
          storiesArticleAsideLink {
            storiesArticleAsideLinkText
            storiesArticleAsideLinkUrl
          }
          storiesArticleAsideLinkColor
          storiesArticleAsideDescription {
            name
            value
          }
        }
        benefits {
          storiesArticleAsideListTitle
          storiesArticleAsideList {
            benefit
          }
        }
      }
    }
  }
  language {
    code
    name
    locale
    slug
  }
`;

module.exports.post = post;
